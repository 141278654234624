<template>
  <div>
    <div class="header">
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="grid-content">
            <router-link to="/">
              <div class="logo"><el-image :src="src"></el-image></div>
              <h2>甘肃全鼎信息科技有限公司</h2>
            </router-link>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="grid-content">
            <ul>
              <li
                v-for="i in navtitle"
                :key="i.path"
                @click="butChang(i.path, i.meta.num)"
                :class="{changeColor:i.meta.num == currentNav.num}"
              >{{i?.meta?.title??''}}</li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="backImg" v-show="isBackImg">
      <el-image :src="backImg"></el-image>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navtitle: [],
      isBackImg: true,
      src: require("@/assets/img/iconImg.png"),
      backImg: require("@/assets/img/abbck.jpg")
    };
  },
  created() {
    this.navtitle = this.$router.options.routes[0].children.map(item => item);
  },
  watch: {
    $route: {
      immediate: true,
      handler(n, o) {
        const imgList = {
          about: 'aboutBck.jpg',
          solve: 'solution.jpg',
          case: 'case.jpg',
          news: 'new2.jpg',
          contact: 'contact.jpg',
          materials: 'materials.jpg',
        }
        if (n.path === "/index") {
          this.isBackImg = false;
        } else {
          let kesFields = n.path.substr(1)
          this.backImg = require('../assets/listImg/'+imgList[kesFields])
          this.isBackImg = true;
        }
      }
    }
  },
  computed: {
    currentNav() {
      return this.$route.meta;
    }
  },
  methods: {
    butChang(path, num) {
      if (num === 6) {
        // this.$message.warning("敬请期待!")
        this.$router.push(path);
      }else {
        if (path === "/") {
        this.$router.push("/");
      } else {
        this.$router.push(path);
      }
      }
      
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  height: 110px;
  width: 80%;
  margin-left: 10%;
  .grid-content {
    text-align: center;
    .logo{
      width: 150px;
      display: inline-block;
      margin-top: 22.5px;
    }
    a {
      h2 {
        font-size: 1.2rem;
      }
    }
  }
  .el-row {
    display: flex;
  }
  .el-col-14 {
    margin-left: auto;
  }
  h2 {
    // line-height: 110px;
    text-align: center;
    font-size: 24px;
  }
  ul {
    display: flex;
    height: 110px;
  }
  ul > * {
    flex: 1 1 0;
  }
  ul li {
    list-style: none;
    text-align: center;
    line-height: 110px;
    cursor: pointer;
  }
  ul li:hover {
    color: #409eff;
    font-size: 18px;
    box-sizing: border-box;
    border-bottom: 2px solid #409eff;
  }
  .changeColor {
    color: #409eff;
    border-bottom: 3px solid #409eff;
  }
}
</style>