<template>
  <div class="footer">
    <div class="footerCont">
      <div class="footerCont-left">
        <p>合作伙伴</p>
          <div class="cooperate">
            <div class="cooperate-img" v-for="item in cooperateImg" :key="item.url">
              <el-image :src="item.url" @click="cooperateFun(item.link)"></el-image>
            </div>
          </div>
      </div>
      <div class="footerCont-right">
        <p>关于我们</p>
        <div class="migTop">
          <div class="rightLink brief">
            <router-link to="about"><li>公司简介</li></router-link>
            <router-link to="case"><li>客户案例</li></router-link>
            <router-link to="news"><li>新闻资讯</li></router-link>
          </div>
          <div class="rightLink brief">
            <li>服务</li>
            <router-link to="solve"><li>解决方案</li></router-link>
            <router-link to="contact"><li>联系我们</li></router-link>
          </div>
          <div class="rightLink address">
            <li>联系我们</li>
            <li>地址：甘肃省兰州市城关区国芳商务写字楼2115</li>
            <li>电话：0931-4892056</li>
            <li>邮箱：qdinfo@qdinfo.xyz</li>
          </div>
        </div>
      </div>
    </div>
    <div class="Record">
      <span>Copyright © 2023 qdinfo.xyz <span class="recordSpan"> All Rights Reserved <span style="cursor: pointer;" @click="cooperateFun('https://beian.miit.gov.cn')">陇ICP备2022000779号-1</span> </span> </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cooperateImg: [
        {url: require("@/assets/listImg/huawei_logo.png"), link: "https://www.huawei.com/cn/"},
        {url: require("@/assets/listImg/lvmeng-logo.png"), link: "https://www.nsfocus.com.cn/"},
        {url: require("@/assets/listImg/ruijie-logo.svg"), link: "https://www.ruijie.com.cn/"},
        {url: require("@/assets/listImg/Hikvision Logo-R.svg"), link: "https://www.hikvision.com/cn/"},
        {url: require("@/assets/listImg/hillstone-networks-logo.svg"), link: "https://www.hillstonenet.com.cn/"},
        {url: require("@/assets/listImg/a8ec374a9.png"), link: "https://www.qingteng.cn/"},
        {url: require("@/assets/listImg/logo-95015.png"), link: "https://www.qianxin.com/"},
        {url: require("@/assets/listImg/skz7mq0za.png"), link: "https://www.lenovo.com.cn/"},
        {url: require("@/assets/listImg/huasan-logo.png"), link: "https://www.h3c.com/cn/"},
      ]
    };
  },
  methods: {
    cooperateFun(url) {
      window.open(url)
    } 
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 280px;
  background-color: #333333;
  .footerCont {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    padding-top: 20px;
    box-sizing: border-box;
    .footerCont-left {
      width: 42%;
      height: 100%;
      float: right;
    }
    .footerCont-right {
      width: 56%;
      height: 100%;
      float: left;
      .migTop{
        overflow: hidden;
        margin-top: 15px;
        margin-left: 4px;
      }
      li{
        color: #747272;
        line-height: 20px;
        margin-top: 15px;
      }
      .rightLink{
        float: left;
      }
      .brief{
        width: 19%;
      }
      .address{
        width: 62%;
      }
    }
  }
  .Record{
    width: 100%;
    height: 40px;
    background-color: #252525;
    text-align: center;
    span{
      color: #acacac;
      line-height: 40px;
    }
    .recordSpan{
      margin-left: 25px;
    }
  }
}

p {
  line-height: 35px;
  color: #747272;
  font-size: 22px;
}
p:first-child {
  line-height: 50px;
  color: #acacac;
  font-size: 20px;
  border-bottom: 1px solid #666666;
}
p:last-child {
  font-size: 16px;
}
p:nth-child(2) {
  margin-top: 20px;
}
span {
  font-size: 12px;
  margin-left: 8px;
}
.cooperate{
  overflow: hidden;
  margin-top: 8px;
  .cooperate-img{
    margin-top: 23px;
    width: 33.3%;
    height: 33px;
    float: left;
    .el-image{
      width: 60%;
      height: 100%;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}

</style>