import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aboutUsContent: [
      { introduce: "甘肃全鼎信息科技有限公司成立于2016年，是一家专业从事系统继承、软件开发、产品销售及全方位IT服务的高新技术企业。公司致力于为客户信息化需求量身定制具有经济性、实用性、先进性的行业整体解决方案同时，提供专业、及时、优质的售后服务。" },
      { introduce: "公司拥有大量的成功方案与优秀口碑。在金融、能源、教育、医疗、文旅、大型企业、数字政府、智慧城市、智慧农业、等多个领域具备广泛的客户基础和行业经验。公司研发中心和技术团队包含：CCIE、CISP、PMP、HCE、OCP、X86server、信息系统项目管理师、网络规划设计师等技术专家，为客户提供融合性系统集成、安全监控、应用系统开发及部署、IT支撑平台建设、云计算构建、数据中心构建、外包维保、信息安全等级保护等信息化解决方案。除了提供数字化解决方案，甘肃全鼎信息科技有限公司还提供高效的技术支持。无论是针对产品使用中的问题，还是对新功能的咨询，我们的专业团队都能够及时响应并提供优质的技术支持，以确保客户能够充分利用我们的服务。甘肃全鼎信息科技有限公司致力于为企业提供智能科技解决方案，可以通过自动化技术和人工智能等技术手段，帮助企业提高生产效率。通过智能化的生产线和设备，可以实现生产过程的自动化和智能化管理，不仅能够减少人力成本，提高生产效率，还能够保证产品质量和一致性，从而提升企业的竞争力。" },
      { introduce: "甘肃全鼎信息科技有限公司未来也始终以“为客户提供优质完善的信息化产品和信息化服务”作为自己的使命，坚持技术创新，以用户应用为中性，以需求为导向，全力打造硬件高稳定性、软件模块化、使用简单化、运维便捷化的高科技IT增值服务。甘肃全鼎信息科技有限公司的智能科技解决方案也致力于提供更好的客户服务。通过人工智能和大数据分析技术，可以对客户的需求进行预测和分析，从而提前满足客户的需求，实现个性化定制。同时，通过智能化的客户服务系统和机器人客服，可以提供24小时不间断的客户支持，快速解决客户问题，提高客户满意度。这样的强化客户服务不仅可以留住现有客户，还可以吸引更多的潜在客户，实现业务的持续增长。" },
    ],
    serviceContent: [
      {id: 1, title: "技术升级与更新", content: "我们致力于不断研究和采用最新的科技成果，为客户提供持续的技术升级和更新服务。包括但不限于系统硬件和软件的升级、网络设备的更新、安全性的加强等，确保客户始终处于科技的最前沿。我们的科技服务团队拥有丰富的经验和专业知识，能够根据客户的需求量身定制解决方案。我们不仅提供技术支持和咨询，还进行定期的系统维护和升级，确保客户的科技设备和系统始终保持高效稳定的运行状态。"},
      {id: 2, title: "定制化解决方案", content: "针对不同客户的需求，我们能够提供定制化的科技解决方案。通过与客户充分沟通和了解，我们将根据客户的具体需求和预算，设计出符合其要求的个性化解决方案，为客户提供更完美的科技服务体验。创新研发部门不断追求技术的突破和创新，致力于开发领先的科技产品和解决方案。通过技术研究与开发，公司能够提供更先进、更高效的技术服务，满足客户的需求。创新研发部门会与各业务部门紧密合作，了解市场需求，将科技与服务紧密结合，为客户提供创新的产品和解决方案。"},
      {id: 3, title: "前沿科技应用", content: "我们密切关注科技领域的最新发展，并深入研究和应用各类前沿科技。无论是人工智能、大数据、物联网还是区块链等，我们能够将这些前沿科技与客户的实际需求相结合，为客户提供更高效、智能化的科技服务。创新研发部门负责策划和管理公司的创新项目。通过市场和技术的研究分析，确定公司创新方向和重点项目，并制定相应的计划和管理方案。创新研发部门与各业务部门合作，将科技与服务有机结合，加速创新项目的进展，并确保项目的高效实施和顺利运行。通过创新项目的策划和管理，公司能够不断推出具有竞争力的新产品和服务，提升市场竞争力。"},
      {id: 4, title: "效率与安全提升", content: "我们注重通过科技服务来优化客户的工作流程，提高工作效率。同时，我们还重视数据和信息的安全，为客户提供可靠的安全保障措施，确保其信息资产的安全性和机密性。"},
      {id: 5, title: "", content: "通过以上方面的创新科技服务，甘肃全鼎信息科技有限公司将与客户携手助力，实现科技与服务的完美结合，为客户提供卓越的科技服务体验。"},
    ],
    certificate: [
      { url: require("@/assets/cate/cate1.jpg") },
      { url: require("@/assets/cate/cate2.jpg") },
      { url: require("@/assets/cate/cate3.jpg") },
      { url: require("@/assets/cate/cate5.jpg") },
      { url: require("@/assets/cate/cate6.jpg") },
      { url: require("@/assets/cate/cate7.jpg") },
      { url: require("@/assets/cate/cate8.jpg") },
      { url: require("@/assets/cate/cate9.jpg") },
      { url: require("@/assets/cate/cate4.jpg") },
      { url: require("@/assets/cate/cate10.jpg") },
      { url: require("@/assets/cate/cate11.jpg") },
    ],
    contactContent: [
      {url: require("@/assets/cate/1.png"), title: "酸功能化离子液体", content: '研发了一系列具有酸功能的离子液体，这些离子液体在化学反应中可以作为催化剂和溶剂使用。它们具有高度的化学稳定性和可调控性，可广泛应用于有机合成、催化反应和材料表面改性等领域。'},
      {url: require("@/assets/cate/2.png"), title: "电解液", content: '开发了先进的电解液产品，用于锂离子电池、超级电容器等能源存储设备。这些电解液具有高电导率、低内阻、优异的电化学稳定性，可以提高能量密度和功率密度，延长电池寿命。'},
      {url: require("@/assets/cate/3.png"), title: "抗静电剂", content: '生产的抗静电剂广泛应用于塑料、纺织品、涂料等行业。这些抗静电剂能有效降低或消除静电带来的不良影响，提高产品的安全性、稳定性和可靠性。'},
      {url: require("@/assets/cate/4.png"), title: "对纤维素溶解性", content: '研发了一系列对纤维素具有良好溶解性的产品，用于生物质资源的高效利用。这些产品可以将纤维素转化为可溶性的化合物或液体，在生物质能源、纤维素制品等领域具有广阔的应用前景。'},
      {url: require("@/assets/cate/5.png"), title: "对CO2气体吸收", content: '致力于研发更高效的CO2吸收材料，用于碳捕集和二氧化碳减排技术。这些材料具有较大的表面积和较高的吸附能力，能够有效地捕集并储存大量的CO2，为解决气候变化和减少温室气体排放提供切实可行的解决方案。'},
      {url: require("@/assets/cate/6.png"), title: "疏水性离子液体", content: '生产的疏水性离子液体在油水分离、防水材料等领域具有广泛应用。这些离子液体具有良好的疏水性能，可以与水相互分离，并能有效分散和稳定油相，改善产品的耐久性和性能稳定性。'},
    ],
    information: [
      { msg: "甘肃全鼎信息科技有限公司" },
      { msg: "联系人 ：李先生" },
      { msg: "联系时间： 9：00——20：00" },
      { msg: "联系地址： 甘肃省兰州市城关区国芳商务写字楼2115" },
      { msg: "联系电话： 0931-4892056" },
      { msg: "传真电话： 15730989319" },
      { msg: "联系邮箱： qdinfo@qdinfo.xyz" },
      { msg: "注： 部分图片素材源自网站，如有侵权请联系删除"}
    ],
    details: [
      {
        id: 1,
        title: "喜报！关于我司被认定为甘肃省2022年第三批高新技术企业",
        url: require("@/assets/img/news1.jpg"),
        content:
          "2022年12月1日，全国高新技术企业认定管理工作领导小组办公室发布通知，认定255家企业为甘肃省2022年第三批高新技术企业，甘肃全鼎信息科技有限公司名列其中。自2016年成立以来，甘肃全鼎信息科技公司努力拓宽业务范围，不断增强技术能力，坚持完善售后服务质量，并参与省级大型电子政务系统建设等大型项目的规划设计、总集成以及业务软件开发等工作，经济、实用、先进的技术，及时、全面、优质的服务得到了各行各业客户的高度满意与好评。为了进一步提升公司的科技地位，更好的吸引人才，享受国家对高科技企业的重点扶持政策，抓住战略机遇期加快企业发展步伐，甘肃全鼎信息科技有限公司根据《高新技术企业认定管理办法》及《高新技术企业认定管理工作指引》的有关规定，经申报、省认定办审查、网上公开等确认，获一致通过，甘肃全鼎信息科技有限公司从此跻身甘肃省高新技术企业之列，为全鼎的腾达发展又搭建了一个高端平台。",
        time: "2022-12-1"
      },
      {
        id: 2,
        title: "甘肃全鼎信息科技有限公司成功入库科技型中小企业",
        url: require("@/assets/img/news2.jpg"),
        content:
          "我司自成立以来，一直专注于系统集成、软件开发、产品销售及全方位IT服务的工作，致力于政府和企业的信息化建设，为客户提供融合性系统集成、安防监控、应用系统开发及部署、IT支撑平台建设、云计算构建、数据中心构建、外包维保、信息安全等级保护等信息化解决方案。多年来，公司不断加强技术实力，不断更新技术方案，不断优化服务系统，拥有了很多的优质政企客户、获得了良好的口碑。公司在2022年申请入驻“科技型中小企业”，递交的相关资料与公司产出的业绩得到了甘肃省科技厅与各相关审核单位的一致认可，于2022年6月成功入驻成为科技型中小企业！未来，我司也会不断做强做大，继续依靠科技成就未来。",
        time: "2022-6-17"
      },
      {
        id: 3,
        title: "热烈祝贺！我公司成功中标甘肃省体育局甘肃省全民健身信息服务系统建设项目",
        content:
          "我公司成功中标甘肃省体育局甘肃省全民健身信息服务系统建设项目，在此，我们对该项目的中标付出辛勤努力的同事们表示热烈的祝贺，希望再接再厉，争取更大的成绩。",
        time: "2021-12-28"
      },
      {
        id: 4,
        title: "我公司成功中标甘肃省体育局青少年体育项目管理系统建设项目",
        content:
          "我司甘肃全鼎信息科技有限公司在甘肃省体育局青少年体育项目管理系统建设招标项目的竞标中，凭借过硬的技术实力，扎实高效的工作作风，卓越的运作团队和良好的企业信誉，以绝对优势顺利中标。",
        time: "2023-4-12"
      },
      {
        id: 5,
        title: "我司被认定为为2022年度第三批“甘肃省科技创新型企业”！",
        content:
          "从甘肃省科学技术厅发布《关于2022年度第三批甘肃省科技创新型企业名单的公示》中获悉，甘肃全鼎信息科技有限公司被甘肃省科技厅认定为甘肃省科技创新型企业。" + 
          "省级科技创新型企业作为科技创新的重要载体，是国家高新技术企业的后备力量。此项认定将进一步推动公司技术攻关、创新研发进程，甘肃全鼎信息科技有限公司将以此为契机，围绕科技创新工作重点，贯彻新发展理念、构建新发展格局，攻坚克难、补足短板，以开阔的眼界、创新的思维，大胆拓展工作思路、探索工作方法，激发科技创新活力和创造潜能，助力公司在新的征程上高质量发展。",
        time: "2022-12-2"
      },
    ],
    solve: [
      {
        url: require("@/assets/img/core.jpg"),
        title: "云计算与数据中心",
        content:
          "提供云计算、云存储、云网络、云安全、云数据库、云管理与部属应用等IT基础设施云服务，支持各类上云业务场景。IT基础设施(主机、存储、网络)、企业级ETL平台、数据存储中心、数据共享服务、应用层、统一门户、数据管理平台建设。模块化+智能化，四大重构(架构、供电、温控、营维)，打造下一代低碳智能数据中心。云计算与数据中心的优势在于提供灵活的计算资源和高效的数据存储，为个人用户和企业提供了便捷的计算和存储解决方案。用户可以根据自己的需求快速获取所需的计算资源，无需购买和维护昂贵的硬件设备。同时，云计算和数据中心的发展也促进了信息技术的创新和应用，推动了互联网的普及和社会数字化的进程。"
      },
      {
        url: require("@/assets/img/core1.jpg"),
        title: "园区网、广域网、城域网",
        content:
          "提供硬件设备，软件设备，网络基础设置，网络设备，网络系统软件，网络基础服务系统，应用软件等组织，打造端到端智能云网解决方案；以数赋智，提供云园区网络、云广域网络、超融合数据中心网络、网络安全四大场景，交付客户一个完整经济、管理极简、稳定可靠、安全高效的计算机网络系统。用于满足园区内企业和机构之间的网络通信需求,可以连接园区内的各个企业和机构，方便信息共享和资源调配，促进园区内的合作与协同。园区网、广域网和城域网都是为了满足不同范围内的网络通信需求而建立的网络。它们在覆盖范围、传输速度、安全性等方面有所差异，但都对于实现信息共享、提高工作效率和促进合作具有重要作用。"
      },
      {
        url: require("@/assets/img/core2.jpg"),
        title: "视频、监控及安防工程",
        content:
          "依赖视频、AI智能、物联网、GIS、融合通讯和大数据核心技术，为用户提供视频会议、室内外智慧屏、无纸化办公、楼宇智能化、视频监控、门禁考勤、防盗报警、智慧停车场、机房工程等软硬一体的解决方案。视频、监控及安防工程广泛应用于各种场所，包括公共场所、商业建筑、居民小区等，为人们提供了有效的安全保障和管理手段。通过实时监测和预警，该系统能够有效减少事故、盗窃、纠纷等安全问题的发生，提高整体的安全性和管理效率。同时，随着技术的不断进步，视频、监控及安防工程也在智能化、网络化方面不断演进，为我们的生活和工作带来更多便利和安全保障。"
      },
      {
        url: require("@/assets/img/core3.jpg"),
        title: "定制化软件开发",
        content:
          "每个行业客户的需求都不尽相同，业务流程都有自己的特色，定制化开" +
          "发将量体裁衣按照具体的需求实现符合客户的特色软件系统。" +
          "项目开发过程遵循CMMI-3规范;具有多个高级项目经理和专业化的项目管理团队，软件质量保障体系满足ISO9001要求。" +
          "针对客户的个性化开发需求，采用CMMI软件工程和能力成熟度规范进行项目定制开发管理，为客户提供保质、准时、高校的开发服务。"
      },
      {
        url: require("@/assets/img/core5.jpg"),
        title: "安全及管理",
        content:
          "预测、防御、检测、响应、让安全一触即达。为用户提供专业的信息安全产品和一站式的安全合规解决方案。安全通信网络：新一代防火墙;安全区域边界：DDoS高防、Web应用防火墙;安全计算环境：企业主机安全、网页防篡改、威胁检测服务、数据库审计、SSL证书、漏洞扫描、数据加密、数据安全中心;安全管理中心：管理检测与响应、态势感知、云堡垒机、日志审计、数据灾备。"
      },
      {
        url: require("@/assets/img/core4.jpg"),
        title: "统一门户信息平台建设",
        content:
          "采用“平台+应用”、“数据+服务”的设计理念，为统一信息门户集成各业务系统提供服务的发布、管理、调取等全流程管理，帮助用户快速构建出基于应用、贯通平台、应用开发者与目标用户的生态链，建立真正开放、稳定、良性的统一门户生态系统。统一门户信息平台建设的目标是实现信息资源的集中管理和共享，提高信息传递的效率和便捷性。通过统一门户，用户可以方便地获取到各类信息，如公司新闻、人事信息、流程审批、内部公告等。同时，该平台还可以与其他业务系统进行对接，实现数据的整合与共享，提升工作效率。"
      },
      {
        url: require("@/assets/img/core6.jpg"),
        title: "一体化运维",
        content:
          "运维支持治理、IT 架构治理、服务能力治理、运营效益治理等方式对为客户提供整体运维外包服务。量身定制 ITSM软件：运维统一门户；自动发现：全网多协议扫描，发现客户网络中各种设备；全栈监控：网络设备、服务器、存储、操作系统、数据库等进行全面深入的监测管理，支持多种数据采集方式；全景展示：根据组织架构、业务逻辑、资产类型等多个维护客户化定制大屏展示；资产智慧管理：智能可视化，机房可视化，管理驾驶舱，多维度报表，自动发现，扫码盘点，在线报修流程化。"
      },
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
