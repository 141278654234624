<template>
  <div>
    <header-item/>
    <router-view/>
    <footer-item/>
  </div>
</template>
<script>
import headerItem from '@/components/HeaderView'
import footerItem from '@/components/FooterView'
export default {
  components: {
    headerItem,
    footerItem
  },
  data() {
    return {
      
    }
  },
}
</script>