import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'

Vue.use(VueRouter)

// 解决导航重复
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
}

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'index',
        meta: {
          num: 0,
          isShow: true,
          title: '首页',
        },
        component: () => import(/* webpackChunkName: "index" */ '@/views/HomeView.vue')
      }, {
        path: 'about',
        name: 'about',
        meta: {
          num: 1,
          isShow: true,
          title: '关于我们',
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
      },
      {
        path: 'solve',
        name: 'solve',
        meta: {
          num: 2,
          isShow: true,
          title: '解决方案',
        },
        component: () => import(/* webpackChunkName: "solve" */ '@/views/SolveView.vue')
      },
      {
        path: 'case',
        name: 'case',
        meta: {
          num: 3,
          isShow: true,
          title: '客户案例',
        },
        component: () => import(/* webpackChunkName: "case" */ '@/views/CaseView.vue')
      },
      {
        path: 'news',
        name: 'news',
        meta: {
          num: 4,
          isShow: true,
          title: '行业资讯',
        },
        component: () => import(/* webpackChunkName: "news" */ '@/views/NewsView.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        meta: {
          num: 5,
          isShow: true,
          title: '联系我们',
        },
        component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactView.vue')
      },
      {
        path: 'materials',
        name: 'materials',
        meta: {
          num: 6,
          isShow: true,
          title: '龙悦"新材料"',
        },
        component: () => import(/* webpackChunkName: "materials" */ '@/views/MaterialsView.vue')
      },
    ],
  },
  {
    path: '/details',
    name: 'details',
    meta: {
      isShow: true,
      title: '列表详情',
    },
    component: () => import(/* webpackChunkName: "details" */ '@/views/DetailsView.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
